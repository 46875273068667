import { Form, Alert } from "antd";
import React from "react";
import { IExtraFormItemProps, IFormItemProps } from "../../../../types";
import { getItemValueProps, handleFormItemLabel } from "../../utils";
import { getFormItem } from "..";

const { ErrorBoundary } = Alert;

const RenderFormItem = (props = {} as IFormItemProps) => {
  let {
    formItemProps = {} as IExtraFormItemProps,
    formListItemPosition, //当在form.list中显示的时候传递当前组件在form.list中的位置
  } = props;

  const form = Form.useFormInstance();

  let { hidden = false, shouldUpdate, style, label, ...restProps } =
    formItemProps || {};

  let _hidden = false;

  //当shoudupdate没设置而hidden方法设置时，将shoudupdate设置为true
  if (
    typeof hidden === "function" ||
    typeof props.formControlProps?.disabled === "function"
  ) {
    shouldUpdate = typeof shouldUpdate === "undefined" ? true : shouldUpdate;
  } else {
    _hidden = hidden;
  }

  //处理form item是否隐藏
  const isControlHidden = () => {
    let _isHide = hidden;

    if (typeof hidden === "function")
      _isHide = hidden?.(form.getFieldsValue(), { formListItemPosition });

    return _isHide;
  };

  //处理不同状态下的label
  let _label = handleFormItemLabel(label, form);

  let _formItemProps = {
    label: _label,
    ...getItemValueProps(props.type),
    ...restProps,
    style: style,
  };

  //通过shouldUpdate属性控制组件的属性
  if (!!shouldUpdate) {
    return (
      <ErrorBoundary>
        <Form.Item
          noStyle={true}
          shouldUpdate={(prev, current, info) =>
            typeof shouldUpdate === "function"
              ? shouldUpdate(prev, current, info)
              : shouldUpdate
          }
        >
          {(formProps) => {
            if (!isControlHidden())
              return (
                <Form.Item {..._formItemProps}>
                  {getFormItem(props, form)}
                </Form.Item>
              );

            return <></>;
          }}
        </Form.Item>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <Form.Item hidden={_hidden} {..._formItemProps}>
        {getFormItem(props, form)}
      </Form.Item>
    </ErrorBoundary>
  );

  return <></>;
};

export default RenderFormItem;
