import React from "react";
import { IFormItemProps } from "../../../../types";
import "./index.scss";
import classNames from "classnames";
import RenderFormItems from "../../formItem";

const RenderFormViewSection = (props: IFormItemProps) => {
  const { title, subTitle, children = [], className, ...restprops } = props;
  return (
    <section className={classNames("form-section split-section", className)}>
      <div className="form-section-head">
        <h3 className="form-section-title">{title}</h3>
        {subTitle && <span className="form-section-subtitle">{subTitle}</span>}
      </div>
      <div className="form-section-body">
        <RenderFormItems
          formItems={children ?? []}
          {...restprops}
        ></RenderFormItems>
      </div>
      <div className="form-section-footer"></div>
    </section>
  );
};

export default RenderFormViewSection;
