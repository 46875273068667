import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import {
  Input,
  InputNumber,
  Select,
  Radio,
  DatePicker,
  Tooltip,
  Table,
  Form,
  Tree,
  Switch,
  Row,
  Col,
  Rate,
} from "antd";

import { FormItemType, IFormItemProps } from "../../../types";
import CustomUpload from "../../upload";
import CustomTreeSelect from "./TreeSelect";
import FormEditor from "./FormEditor";
import ColorPicker from "./ColorPicker";
import CustomCheckbox from "./CustomCheckbox";
import CustomCascader from "./CustomCascader";
import CustomDatepicker from "./CustomDatepicker";
import CustomRadio from "./CustomRadio";

export const placeholderFormat = (formItemProp) => {
  let { label, placeholder } = formItemProp || {};
  if (!!placeholder) return placeholder;
  if (!label) return "...";

  if (!!label?.props?.children) {
    return label.props.children
      .filter((item) => {
        return !React.isValidElement(item);
      })
      .join("");
  }

  return label;
};
const CustomRenderControl = (props) => {
  const { render: Render, formControlProps, ...restProps } = props;

  return <Render formControlProps={formControlProps} {...restProps} />;
};

const getFormControl = (props: IFormItemProps, formInstance?: any) => {
  const ItemType: FormItemType | string = props.type
    ? props.type.toLowerCase()
    : "input";

  //表单控件的属性参数
  const {
    showTime = false,
    options,
    label = "",
    render,
    conditionFilter,
    ...resCtrlProps
  } = props.formControlProps || {};

  if (render) {
    return (
      <CustomRenderControl
        render={render}
        index={props.index}
        formControlProps={{ ...resCtrlProps, options }}
      />
    );
  }
  switch (ItemType) {
    case "checkbox":
      return <CustomCheckbox label={label} {...props.formControlProps} />;
    case "switch":
      return <Switch {...resCtrlProps}></Switch>;
    case "radio":
      return (
        <CustomRadio label={label} {...props.formControlProps}></CustomRadio>
      );
    case "number":
    case "inputnumber":
      const { allowClear, ...restProps } = resCtrlProps;
      return (
        <InputNumber
          placeholder={`请输入${placeholderFormat(props.formItemProps)}`}
          {...restProps}
        />
      );
    case "date":
      return (
        <CustomDatepicker
          placeholder={`请选择${placeholderFormat(props.formItemProps)}`}
          showTime={showTime}
          {...resCtrlProps}
        ></CustomDatepicker>
      );
    case "daterange":
      return (
        <DatePicker.RangePicker
          showTime={showTime}
          format={showTime === false ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss"}
          {...resCtrlProps}
        ></DatePicker.RangePicker>
      );
    case "select":
      const _options =
        typeof options === "function" ? options(formInstance) : options || [];
      // console.log(_options, "---options");
      return (
        <Select
          allowClear={true}
          placeholder={`请选择${placeholderFormat(props.formItemProps)}`}
          options={_options}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          {...resCtrlProps}
        />
      );
    case "editor":
      return <FormEditor {...resCtrlProps}></FormEditor>;
    case "textarea":
      const { TextArea } = Input;
      return (
        <TextArea
          autoComplete="off"
          allowClear={true}
          placeholder={`请输入${placeholderFormat(props.formItemProps)}`}
          autoSize={{ minRows: 3, maxRows: 10 }}
          {...resCtrlProps}
        ></TextArea>
      );
    case "cascader":
      return <CustomCascader {...resCtrlProps} options={options} />;
    case "treeselect":
      return <CustomTreeSelect {...resCtrlProps}></CustomTreeSelect>;
    case "tree":
      return <CustomTreeControl {...resCtrlProps} />;
    case "label":
      return <CustomLabel {...resCtrlProps}></CustomLabel>;

    case "table":
      return <CustomTable {...resCtrlProps}></CustomTable>;
    case "upload":
      return (
        <CustomUpload
          {...resCtrlProps}
          formItemName={props.formItemProps?.name}
        ></CustomUpload>
      );
    case "colorpicker":
      return <ColorPicker {...resCtrlProps}></ColorPicker>;
    case "rate":
      return <Rate {...resCtrlProps} />;
    case "password":
      return (
        <Input.Password
          allowClear={true}
          placeholder={`请输入${placeholderFormat(props.formItemProps)}`}
          {...resCtrlProps}
        />
      );
    default:
      return (
        <Input
          allowClear={true}
          placeholder={`请输入${placeholderFormat(props.formItemProps)}`}
          {...resCtrlProps}
        />
      );
  }
};

const CustomLabel = (props: any = {}) => {
  const { tooltip, value, style = {}, ...restProps } = props;
  if (!!tooltip) {
    const _props = typeof tooltip === "object" ? tooltip : {};

    const _style = {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      textOverflow: "ellipsis",
      width: "160px",
      ...style,
    };

    return (
      <Tooltip title={value ?? ""} {..._props}>
        <span style={_style} {...restProps}>
          {value ?? ""}
        </span>
      </Tooltip>
    );
  }

  return <label {...restProps}>{value}</label>;
};

const CustomTable = (props) => {
  if (!props.value) return;

  if (!Array.isArray(props.value)) {
    console.error("该字段不能为非数组集合");
    return (
      <Table
        pagination={false}
        columns={[]}
        rowKey={(item, index) => Symbol(index).toString()}
        size="small"
        dataSource={[]}
      ></Table>
    );
  }
  let { columns } = props;

  if (!columns) {
    const _val = props.value[0];

    columns = Object.keys(_val).map((item) => {
      return {
        dataIndex: item,
        title: item,
      };
    });
  }

  return (
    <Table
      pagination={false}
      columns={columns}
      rowKey={(item, index) => Symbol(index).toString()}
      size="small"
      dataSource={props.value}
    ></Table>
  );
};

export const CustomTreeControl = (props: any) => {
  let [expandKeys, setExpandKeys] = useState([]);

  useEffect(() => {
    setExpandKeys([...(props.data || [])]);
  }, [props.data]);

  return (
    <Tree
      {...props}
      checkedKeys={props.data || []}
      defaultExpandedKeys={props.data || []}
      defaultExpandParent={true}
      onCheck={(evt) => {
        props.onChange(evt);
      }}
    />
  );
};

export const CustomFilterControl = (props) => {
  let { value, label, children: MainControl, ...restProps } = props;
  const [filterValue, setFilterValue] = useState(value);

  const onSelectChange = (val) => {
    setFilterValue({ ...filterValue, condition: val });
  };

  const onInputChange = (evt) => {
    setFilterValue({ ...filterValue, condition: evt.target.value });
  };

  const onControlChange = (evt) => {
    try {
      if (evt.target)
        setFilterValue({ ...filterValue, value: evt.target.value });
      else setFilterValue({ ...filterValue, value: evt });
    } catch (ex) {
      console.warn("custom filter form control issue:", ex);
    }
  };

  restProps.formControlProps = {
    ...restProps.formControlProps,
    onChange: onControlChange,
    value: filterValue?.value,
  };

  const form = Form.useFormInstance();

  useEffect(() => {
    const _name = props.formItemProps.name;
    if (form.getFieldValue(_name) !== filterValue?.value)
      form.setFieldValue(_name, filterValue);
  }, [filterValue]);

  return (
    <div style={{ display: "inline-flex", width: "100%" }}>
      <Select
        style={{ width: "80px" }}
        placeholder={"条件"}
        onChange={onSelectChange}
        value={filterValue?.condition}
        options={[
          { label: ">", value: "big" },
          { label: "<", value: "less" },
          { label: "=", value: "equal" },
        ]}
        allowClear={true}
        dropdownRender={(menus, ...args) => {
          return (
            <>
              {menus}
              <div style={{ padding: "5px 10px" }}>
                <Input onChange={onInputChange} allowClear={true}></Input>
              </div>
            </>
          );
        }}
      ></Select>
      <div style={{ flex: "1 0 auto", marginLeft: "8px" }}>
        <MainControl {...restProps} />
      </div>
    </div>
  );
};

export default getFormControl;
