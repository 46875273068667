import React from "react";
import { IFormLayout } from "../../types";

const FormLayout = (props: IFormLayout) => {
  const { title, children } = props;
  return (
    <div className="form-layout">
      {title && <h3 className="title">{title}</h3>}
      {children}
    </div>
  );
};

export default FormLayout;
