import classNames from "classnames";
import React from "react";
import RenderFormItems from "../../formItem";
import { Collapse, Form } from "antd";
import RenderFormItemNormal from "../FormItemNormal";

const { Panel } = Collapse;

const getPanelExtra = (props) => {
  const { extra, form } = props;

  if (!props.extra) return <></>;

  if (typeof extra === "function") {
    const ExtraComponent = extra;

    return <ExtraComponent form={form} />;
  }

  if (!!extra.$$typeof) {
    return <>{extra}</>;
  }

  if (Array.isArray(extra)) {
    return (
      <>
        <RenderFormItems formItems={extra ?? []} />
      </>
    );
  }

  if (!!extra?.type || extra?.formItemProps) {
    return (
      <>
        <RenderFormItemNormal {...(extra || {})} />
      </>
    );
  }

  return <></>;
};

const CollapsePanel = (props) => {
  let {
    title,
    children = [],
    extra,
    shouldUpdate = false,
    hidePanel,
    hideCollapse,
    hidePanelContent,
    formItemProps,
    ...restprops
  } = props;

  const formInstance = Form.useFormInstance();

  //隐藏panel
  const _hidePanel = () => {
    if (typeof hidePanel === "undefined") return false;

    if (typeof hidePanel === "boolean") return hidePanel;

    if (typeof hidePanel === "function") {
      return hidePanel?.(formInstance.getFieldsValue(), formInstance);
    }
  };

  //隐藏整个折叠面板
  const _hideCollpase = () => {
    if (typeof hideCollapse === "undefined") return false;

    if (typeof hideCollapse === "boolean") return hidePanel;

    if (typeof hideCollapse === "function") {
      return hideCollapse?.(formInstance.getFieldsValue(), formInstance);
    }
  };

  //隐藏面板内容
  const _hidePanelContent = () => {
    if (typeof hidePanelContent === "undefined") return false;

    if (typeof hidePanelContent === "boolean") return hidePanelContent;

    if (typeof hidePanelContent === "function") {
      return hidePanelContent?.(formInstance.getFieldsValue(), formInstance);
    }
  };

  //只要存在隐藏面板panel或者隐藏折叠面板或者隐藏内容的情况下强制设置shoudupdate为true
  if (!!hideCollapse || !!hidePanel || !!hidePanelContent) {
    shouldUpdate = true;
  }

  let _form = Form.useFormInstance();

  if (shouldUpdate)
    return (
      <Form.Item noStyle shouldUpdate={shouldUpdate}>
        {() => {
          return (
            <>
              {!_hideCollpase() && (
                <Collapse
                  collapsible="header"
                  defaultActiveKey={["0"]}
                  showArrow={true}
                  {...restprops}
                >
                  {!_hidePanel() && (
                    <Panel
                      header={<>{title}</>}
                      key={"0"}
                      extra={getPanelExtra({ extra, form: _form })}
                    >
                      {!_hidePanelContent() && (
                        <div className="ant-form-item-container">
                          <RenderFormItems
                            formItems={children ?? []}
                            {...restprops}
                          ></RenderFormItems>
                        </div>
                      )}
                    </Panel>
                  )}
                </Collapse>
              )}
            </>
          );
        }}
      </Form.Item>
    );

  return (
    <Form.Item noStyle>
      <Collapse
        collapsible="header"
        defaultActiveKey={["0"]}
        showArrow={true}
        style={{ marginBottom: "12px" }}
        {...restprops}
      >
        <Panel
          header={<>{title}</>}
          key={"0"}
          extra={getPanelExtra({ extra, form: _form })}
        >
          <div className="ant-form-item-container">
            <RenderFormItems
              formItems={children ?? []}
              {...restprops}
            ></RenderFormItems>
          </div>
        </Panel>
      </Collapse>
    </Form.Item>
  );
};

export default CollapsePanel;
