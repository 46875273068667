import { Button, Form, Popconfirm, Alert } from "antd";
import React from "react";

const { ErrorBoundary } = Alert;

const RemoveButton = (props) => {
  const { fieldName, remove } = props;

  const ButtonWrapper = (
    <Popconfirm title="确定移除该条记录？" onConfirm={() => remove(fieldName)}>
      <Button type="link" icon={<>&times;</>} danger>
        {props.removeComponent ? <>{props.removeComponent}</> : <>移除 </>}
      </Button>
    </Popconfirm>
  );

  return (
    <>
      <ErrorBoundary>
        {props.container ? (
          <props.container>{ButtonWrapper}</props.container>
        ) : (
          <>{ButtonWrapper}</>
        )}
      </ErrorBoundary>
    </>
  );
};

export default RemoveButton;
