import { Button, Empty, Form, Popconfirm, Space } from "antd";
import React, { useCallback } from "react";
// import RenderInlineFormItem from "../FormItemInline";
import { IFormItemProps, IFormListItemProps } from "../../../../types";
import { handleFormListItemLabel } from "../../utils";
import cloneDeep from "../../../util/cloneDeep";
import classNames from "classnames";
import RenderFormItem from "../FormItemNormal";

const RemoveButtonComponent = (props) => {
  if (!props.removeComponent) return <>&times; 移除 </>;

  if (
    Object.prototype.toString.call(props.removeComponent) ===
    "[object Function]"
  )
    return <>{props.removeComponent()}</>;

  return <>{props.removeComponent}</>;
};

const RenderFormListItem = (props: IFormListItemProps) => {
  const {
    addButtonText = "添加",
    addButtonIcon = <>+</>,
    removable,
    children,
    min,
    max,
    spaceProps = {},
    parentRowIndex,
    addable,
  } = props;

  if (!children || children?.length === 0) {
    return <Empty></Empty>;
  }

  const {
    hidden = false,
    name = "list",
    rules = [],
    label = "",
    noStyle = false,
    disabled = false,
    editable = true,
    className = "",
    ...restProps
  } = props.formItemProps || {};

  const form = Form.useFormInstance();

  console.log(props, "initialValue");

  //判断是否显示移除按钮
  const showRemoveButton = (fields, itemIndex) => {
    let _removable = removable;

    if (_removable === false) {
      return false;
    }

    if (typeof removable === "undefined") _removable = true;

    return (
      ((typeof _removable === "function" &&
        !_removable?.({
          ...props,
          type: "operator",
          itemIndex,
          data: form.getFieldValue(name)?.[itemIndex],
        })) ||
        _removable) &&
      (typeof min === "undefined" || fields.length > min)
    );
  };

  return (
    <Form.Item noStyle={noStyle} {...restProps}>
      <Form.List name={name} {...restProps}>
        {(fields, { add, remove }) => {
          return (
            <div className={className}>
              {fields.map(
                ({ key, name: fieldName, ...restField }, itemIndex) => (
                  <Space
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                    direction="horizontal"
                    wrap={true}
                    key={key}
                    {...spaceProps}
                  >
                    {children.map(
                      (child: IFormListItemProps, index: number) => {
                        //对象深拷贝
                        let _child = cloneDeep(child, true) as IFormItemProps &
                          IFormListItemProps;

                        let _name = _child["formItemProps"]["name"] as
                          | string
                          | number;

                        const { className } = _child.formItemProps;

                        //嵌套类型为list时递归循环
                        if (_child.type === "list") {
                          _child.formItemProps.name = [fieldName, _name];
                          //处理当前list层级
                          _child.parentRowIndex = [
                            ...(_child.parentRowIndex || []),
                            ...[itemIndex],
                          ];

                          return (
                            <div
                              className={classNames(
                                "form-item-list-nested",
                                className
                              )}
                              key={`sub-list-form-item-${index}`}
                            >
                              <RenderFormListItem
                                {..._child}
                              ></RenderFormListItem>
                            </div>
                          );
                        }

                        //合并生成新的Form.Item的属性
                        _child.formItemProps = {
                          ...restField,
                          ...child.formItemProps,
                          name: [fieldName, _name],
                          label: handleFormListItemLabel(
                            _child?.formItemProps?.label,
                            itemIndex
                          ),
                        };

                        //合并处理组件的属性
                        _child.formControlProps = {
                          ...(child.formControlProps || {}),
                          disabled:
                            props?.isDisabled?.(
                              child,
                              index,
                              itemIndex,
                              form.getFieldValue(name)?.[itemIndex]?.[_name],
                              form.getFieldValue(name)?.[itemIndex]
                            ) ?? false,
                        };

                        return (
                          <RenderFormItem
                            key={`list-form-item-${index}`}
                            {..._child}
                            formListItemPosition={{
                              rowIndex: itemIndex,
                              columnIndex: index,
                            }}
                            parentRowIndex={parentRowIndex}
                          />
                        );
                      }
                    )}
                    {showRemoveButton(fields, itemIndex) && (
                      <div className="form-item-list-row-actionbar">
                        <Popconfirm
                          title="确定移除该条记录？"
                          onConfirm={() => remove(fieldName)}
                        >
                          <Button
                            type="link"
                            key={`list-item-remove-${props.index}`}
                            danger
                          >
                            <RemoveButtonComponent
                              {...props}
                            ></RemoveButtonComponent>
                          </Button>
                        </Popconfirm>
                      </div>
                    )}
                  </Space>
                )
              )}

              {!disabled &&
                editable &&
                addable != false &&
                (typeof max === "undefined" || max > fields.length) && (
                  <Button
                    icon={<>{addButtonIcon}</>}
                    type="dashed"
                    onClick={() => add()}
                  >
                    {addButtonText}
                  </Button>
                )}
            </div>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

export default RenderFormListItem;
