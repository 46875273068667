import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
  useCallback,
} from "react";
import { FormComponent } from "../common";
import { Button, Drawer, Empty, FormInstance, Space } from "antd";
import { IDrawerFormProps, IFormItemProps, IModalFormRef } from "../../types";
import "./index.scss";

const DrawerForm: React.FC<IDrawerFormProps> = React.forwardRef(
  (props: IDrawerFormProps, ref: React.Ref<IModalFormRef>): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(false);
    const formRef = useRef<FormInstance>({} as FormInstance);

    const {
      formItems,
      onOk = () => {},
      onCancel = () => {},
      resetOnClose = true,
      closable = true,
      width = 680,
      drawFormProp = {},
      EmptyDescription = "暂无数据",
      defaultOpen = false,
      showActionBar,
      ...restProps
    } = props;

    useImperativeHandle<IModalFormRef, IModalFormRef>(
      ref,
      () => ({
        setVisible,
        ...formRef.current,
      }),
      []
    );

    const onClose = () => {
      setVisible(false);
      formRef.current.resetFields();
      onCancel();
    };

    const onSave = async () => {
      const validate = await formRef.current.validateFields();

      onOk(formRef.current.getFieldsValue());
      setVisible(false);

      if (resetOnClose) {
        formRef.current.resetFields();
      }
    };

    useEffect(() => {
      if (props.data) {
        formRef.current.setFieldsValue(props.data);
      }
    }, [props.data]);

    useEffect(() => {
      setVisible(true);
    }, [defaultOpen]);

    let ExtraComponent = useCallback(() => {
      return (
        <>
          {formItems && showActionBar != false && (
            <Space>
              <Button onClick={onClose}>取消</Button>
              <Button type="primary" onClick={onSave}>
                保存
              </Button>
            </Space>
          )}
        </>
      );
    }, [formItems]);

    return (
      <Drawer
        open={visible}
        width={width}
        maskClosable={false}
        destroyOnClose={true}
        onClose={onClose}
        title={props.title ?? " "}
        closable={closable}
        forceRender={true}
        extra={<ExtraComponent />}
        {...(drawFormProp || {})}
      >
        {(formItems || []).length === 0 && (
          <div className="drawer-empty">
            <Empty description={EmptyDescription} />
          </div>
        )}
        <FormComponent
          ref={formRef}
          formItems={formItems}
          preserve={false}
          {...restProps}
        />
      </Drawer>
    );
  }
);

export default DrawerForm;
