import React from "react";
import { Button, Card, Form, Popconfirm, Space } from "antd";
import RenderFormItem from "../FormItemNormal";
import { IFormItemProps } from "../../../../types";
import cloneDeep from "../../../util/cloneDeep";
import AddButton from "./addButton";
import RemoveButton from "./removeButton";
import { isAddable, isRemovable } from "./util";

export const CardList = (props) => {
  const {
    columns,
    removable,
    formListItemActionButtons,
    getDefaultValue,
    min,
    max,
    name,
    editable,
    disabled,
    cardProps,
    spaceProps = {},
    addButtonText,
    removeComponent,
    ...restProps
  } = props;

  let form = Form.useFormInstance();

  return (
    <>
      <Form.List name={name} {...restProps}>
        {(fields, { add, remove, move }) => {
          return (
            <Space direction="vertical" {...spaceProps}>
              {(fields || []).length === 0 && editable != false && (
                <AddButton
                  add={() => add(getDefaultValue(fields.length + 1), 0)}
                ></AddButton>
              )}
              {fields.map(
                ({ key, name: fieldName, ...restField }, itemIndex) => {
                  let _actions = [];

                  //允许添加
                  if (
                    isAddable({
                      disabled,
                      editable,
                      max,
                      fieldLength: fields.length,
                    })
                  ) {
                    _actions = [
                      ..._actions,
                      ...[
                        <AddButton
                          key={`btn-add_${itemIndex}`}
                          add={() =>
                            add(
                              getDefaultValue(fields.length + 1),
                              itemIndex + 1
                            )
                          }
                        ></AddButton>,
                      ],
                    ];
                  }

                  if (
                    isRemovable({
                      removable,
                      data: form.getFieldValue(name)?.[itemIndex],
                      editable,
                      itemIndex,
                      min,
                      fieldLength: fields.length + 1,
                    })
                  ) {
                    _actions = [
                      ..._actions,
                      ...[
                        <RemoveButton
                          removeComponent={removeComponent}
                          fieldName={fieldName}
                          remove={remove}
                          key={`btn-remove_${itemIndex}`}
                        ></RemoveButton>,
                      ],
                    ];
                  }

                  if (
                    formListItemActionButtons?.(
                      itemIndex,
                      form.getFieldValue(name)?.[itemIndex]
                    )?.length > 0
                  ) {
                    _actions = [
                      ..._actions,
                      ...formListItemActionButtons?.(
                        itemIndex,
                        form.getFieldValue(name)?.[itemIndex]
                      ),
                    ];
                  }

                  return (
                    <Card
                      size={"small"}
                      hoverable={true}
                      key={`form-list-card-item-${itemIndex}`}
                      actions={_actions}
                      {...(cardProps || {})}
                    >
                      {(columns || []).map(
                        (child: IFormItemProps, ctrlIndex: number) => {
                          const { label } = child.formItemProps || {};
                          let _child = cloneDeep(child, "object");

                          const _name = _child["formItemProps"]["name"] as
                            | string
                            | number;

                          _child.formItemProps = {
                            ...restField,
                            ..._child.formItemProps,
                            label,
                            name: [fieldName, _name],
                            shouldUpdate: () => !!props?.isDisabled,
                            labelCol: { span: 8 },
                            wrapperCol: { span: 16 },
                          };

                          const isDisabled = (disabledParam, ...params) => {
                            if (typeof disabledParam === "function") {
                              return disabledParam(params);
                            }

                            return !!disabledParam;
                          };

                          //合并可控组件的属性
                          _child.formControlProps = {
                            size: "small",
                            ...(child.formControlProps || {}),
                            disabled: isDisabled(
                              child.formControlProps?.disabled,
                              child,
                              ctrlIndex,
                              itemIndex,
                              form.getFieldValue(name)?.[itemIndex]?.[_name],
                              form.getFieldValue(name)?.[itemIndex]
                            ),
                          };

                          return (
                            <RenderFormItem
                              key={`form-list-card-item-${itemIndex}_ctrlIndex_${ctrlIndex}`}
                              {..._child}
                              formListItemPosition={{
                                rowIndex: itemIndex,
                                columnIndex: ctrlIndex,
                              }}
                              index={ctrlIndex}
                            />
                          );
                        }
                      )}
                    </Card>
                  );
                }
              )}
            </Space>
          );
        }}
      </Form.List>
    </>
  );
};
