import React from "react";
import { IFormItemProps } from "../../../types";
import getFormControl, { CustomFilterControl } from "./formControl";

//生成form item
const getFormItem = (props: IFormItemProps, formInstance?: any) => {
  if (props.formControlProps?.conditionFilter) {
    return (
      <CustomFilterControl {...props}>
        {(props) => {
          return getFormControl(props, formInstance);
        }}
      </CustomFilterControl>
    );
  }

  let _props = {
    ...props,
  };

  if (
    Object.prototype.toString.call(props.formControlProps?.disabled) ===
    "[object Function]"
  ) {
    //@ts-ignore
    const _disabled = props.formControlProps.disabled(
      formInstance.getFieldsValue() ?? {}
    );
    _props = {
      ...props,
      formControlProps: {
        ...props.formControlProps,
        disabled: _disabled,
      },
    };
  }

  return getFormControl(_props, formInstance);
};

export { getFormItem };
