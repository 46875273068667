import { Button } from "antd";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import React from "react";

export interface IFormListAddButtonProps {
  add: (data: any, index?: number) => void;
  addButtonText?: React.ReactNode;
  container?: React.ReactNode | any;
}

const AddButton = (props: IFormListAddButtonProps) => {
  let { add, addButtonText } = props;

  let ButtonWrapper = (
    <Button icon={<>+</>} type="link" onClick={add}>
      {addButtonText || "添加"}
    </Button>
  );

  return (
    <>
      <ErrorBoundary>
        {props.container ? (
          <props.container>{ButtonWrapper}</props.container>
        ) : (
          <>{ButtonWrapper}</>
        )}
      </ErrorBoundary>
    </>
  );
};

export default AddButton;
