import { Checkbox, Form, Row, Col } from "antd";
import React from "react";

const CustomCheckbox = (props) => {
  const { options, label = "", ...restCtrlProps } = props;

  const formInstance = Form.useFormInstance();

  if (options) {
    const { columnSpan, ...restProps } = restCtrlProps;

    if (typeof columnSpan === "undefined") {
      return (
        <Checkbox.Group
          {...restProps}
          options={
            Object.prototype.toString.call(options) === "[object Function]"
              ? options(formInstance, restCtrlProps)
              : options
          }
        ></Checkbox.Group>
      );
    }

    return (
      <Checkbox.Group {...restProps}>
        <Row>
          {(Object.prototype.toString.call(options) === "[object Function]"
            ? options(formInstance, restCtrlProps)
            : options
          ).map((item, index) => {
            const { component: Component, ...restItem } = item;

            return (
              <Col key={`column-index-${index}`} xs={24} sm={24 / columnSpan}>
                {!Component ? (
                  <Checkbox
                    value={item.value}
                    disabled={item.disabled || false}
                  >
                    {item.label}
                  </Checkbox>
                ) : Object.prototype.toString.call(Component) ===
                  "[object Function]" ? (
                  <Component {...props} {...restItem}></Component>
                ) : (
                  <>{Component}</>
                )}
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
    );
  }
  return <Checkbox {...restCtrlProps}>{label}</Checkbox>;
};

export default CustomCheckbox;
