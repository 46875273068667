import React, { useTransition } from "react";
import BundledEditor from "../../../../../TextEditor";

const FormEditor = (props) => {
  const { value, onChange, ...restProps } = props;

  return (
    <BundledEditor
      onChange={(evt) => {
        onChange(evt?.target?.getContent());
      }}
      initialValue={value || ""}
      {...restProps}
    ></BundledEditor>
  );
};

export default FormEditor;
