import React, { useCallback, useImperativeHandle } from "react";
import { Form, FormProps, Space } from "antd";
import RenderFormItems, { RenderRowFormItems } from "./formItem";
import "./index.scss";
import { FormInstance } from "antd/es/form/Form";
import { IFilterFormProps, IFormProps } from "../../types";
import classNames from "classnames";

const _defaultFormLayout = {
  labelCol: { span: 6, xs: 24, sm: 12, md: 6, xl: 4 },
  wrapperCol: { span: 18, xs: 24, sm: 12, md: 18, xl: 20 },
};

export const DefaultFormLayout = _defaultFormLayout;

export const FormComponent = React.forwardRef(
  (props: IFormProps & IFilterFormProps, ref: any) => {
    const [form] = Form.useForm<FormInstance>();
    const {
      labelCol,
      wrapperCol,
      formItems,
      formType,
      actionButtons,
      actionButtonProp,
      rowLayout,
      itemsPerLine,
      actionButtonLayout,
      actionButtonsPosition = "inline",
      ...restFormProps
    } = props || {};

    const _layout = {
      labelCol: labelCol || _defaultFormLayout.labelCol,
      wrapperCol: wrapperCol || _defaultFormLayout.wrapperCol,
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          ...form,
        };
      },
      []
    );

    return (
      <Form
        autoComplete="off"
        form={form}
        scrollToFirstError={true}
        preserve={false}
        labelWrap={true}
        {..._layout}
        {...restFormProps}
      >
        <>
          {formType === "filter-form" ? (
            <RenderRowFormItems
              itemsPerLine={itemsPerLine}
              formItems={formItems}
              layout={_layout}
              rowLayout={rowLayout || {}}
              actionButtons={actionButtons}
              actionButtonsPosition={actionButtonsPosition}
            />
          ) : (
            <>
              <RenderFormItems
                itemsPerLine={itemsPerLine}
                formItems={formItems}
                layout={_layout}
                disabled={restFormProps.disabled}
              />
              {actionButtons && !restFormProps.disabled && (
                <Form.Item
                  key={"form-actionbar-item"}
                  className={classNames(
                    "form-actionbar-item",
                    actionButtonsPosition === "fixed" && "form-actionbar-fixed"
                  )}
                  wrapperCol={{
                    xs: { span: 24 },
                    sm: {
                      span: actionButtonsPosition === "fixed" ? 24 : 12,
                      offset: 6,
                    },
                  }}
                  {...(actionButtonProp || {})}
                >
                  {actionButtons()}
                </Form.Item>
              )}
            </>
          )}
        </>
      </Form>
    );
  }
);

export default (props: IFormProps) => {
  const [form] = Form.useForm<FormInstance>();

  const {
    labelCol,
    wrapperCol,
    formItems,
    formType,
    actionButtons,
    ...restFormProps
  } = {
    ...props,
  };

  const _layout = {
    labelCol: labelCol || _defaultFormLayout.labelCol,
    wrapperCol: wrapperCol || _defaultFormLayout.wrapperCol,
  };

  return {
    FormContent: useCallback(
      (subprops?: IFilterFormProps & IFormProps) => (
        <>
          <Form
            autoComplete="off"
            form={form}
            scrollToFirstError={true}
            preserve={false}
            labelWrap={true}
            {..._layout}
            {...restFormProps}
          >
            {formType === "filter-form" ? (
              <RenderRowFormItems
                itemsPerLine={subprops.itemsPerLine}
                formItems={formItems}
                layout={_layout}
                rowLayout={subprops.rowLayout || {}}
                actionButtons={subprops.actionButtons}
                actionButtonsPosition={subprops.actionButtonsPosition}
              />
            ) : (
              <>
                <RenderFormItems
                  itemsPerLine={subprops.itemsPerLine}
                  formItems={formItems}
                  layout={_layout}
                  disabled={restFormProps.disabled}
                />
                {actionButtons && !restFormProps.disabled && (
                  <Form.Item
                    key={"form-actionbar-item"}
                    className={classNames("form-actionbar-item")}
                    wrapperCol={{
                      xs: { span: 24 },
                      sm: { span: 16, offset: 6 },
                    }}
                    style={{ textAlign: "right", paddingTop: "20px" }}
                  >
                    {actionButtons()}
                  </Form.Item>
                )}
              </>
            )}
          </Form>
        </>
      ),
      [props.formItems]
    ),
    Instance: form,
  };
};
