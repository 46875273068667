import React from "react";
import {Descriptions} from 'antd';


const DescriptionForm = (props) => {
const layout = props.layout ||{
    labelCol: { span: 10 },
    wrapperCol: { span:14  }, 
}

const getFields = (item) => {
    const {type,value,itemProps} = item;
    if(item.itemProps && item.itemProps.render){
        return item.itemProps.render(item);
    }

    switch (type){
        case 'img':
            const text = !!value && value.length > 0 ? value.map((imgItem,index)=>{
                return <img  {...item.itemProps} src={ !!value && value[0].picUrl} key={index} />;
             }) : '---';

            return text;
            
        default:
            return !!value ? value : '---';
    } 
}

const renderContent=()=>{
    return <>
        {
            <Descriptions layout={layout} {...props.descriptionExtend || {}}>
                {
                    props.formItems.map((item, index)=>{
                        return  <Descriptions.Item label={item.label} key={index + 1} {...(item.descItemProps||{})} className={item.className || ''}>
                                {getFields(item)}
                            </Descriptions.Item>;
                    })
                }
            </Descriptions>
        }
    </>;
};

  

    return {
        content:renderContent(),
    };
};

export default DescriptionForm;