import RenderFormItemNormal from "../FormItemNormal";
import { Input, Form } from "antd";
import React from "react";
import { getFormItem } from "..";
import cloneDeep from "../../../util/cloneDeep";
import { IFormItemProps } from "../../../../types";

const InputGroupInternal = (props) => {
  const { children } = props;

  return (
    <Input.Group>
      {children.map((item: IFormItemProps, index) => {
        let formItemProps = cloneDeep(item.formItemProps, true);

        return (
          <RenderFormItemNormal
            {...item}
            key={`input-group-${props.formItemprops?.name ?? index}`}
            formItemProps={{
              ...formItemProps,
              name: props.name
                ? [props.name, formItemProps.name]
                : formItemProps.name,
            }}
          ></RenderFormItemNormal>
        );
      })}
    </Input.Group>
  );
};

const RenderInputGroup = (props) => {
  const { children, formItemProps } = props;
  const form = Form.useFormInstance();

  if (!children || children.length === 0) return <></>;

  return (
    <Form.Item noStyle {...formItemProps} wrapperCol={{ span: 24 }}>
      <InputGroupInternal children={children} name={formItemProps.name} />
    </Form.Item>
  );
};

export default RenderInputGroup;
