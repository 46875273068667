import React, {
  useReducer,
  useEffect,
  useRef,
  useImperativeHandle,
} from "react";
import { FormComponent } from "../common";
import { Button, FormInstance, Space } from "antd";
import { IFilterFormProps } from "../../types";
import "./index.scss";

const FilterReducer = (state = {} as any, { type, data }) => {
  switch (type) {
    case "expand":
      return {
        ...state,
        ...(typeof data === "boolean" ? { expand: data } : data),
      };
    case "count":
      return { ...state, count: data };
    default:
      return state;
  }
};

const FilterForm: React.FC<IFilterFormProps> = React.forwardRef(
  (props: IFilterFormProps, ref: any): JSX.Element => {
    if (props.formItems?.length === 0) {
      return <></>;
    }

    const formRef = useRef<FormInstance>({} as FormInstance);

    const {
      actionButtonsPosition,
      actionButtons,
      SearchIcon = ({ children }) => <>{children}</>,
      ResetIcon = ({ children }) => <>{children}</>,
      ExpandIcon = ({ children }) => <>{children}</>,
      expandable,
      onSearch,
      defaultExpand = true,
      defaultShowCount = 2,
      formItems,
      itemsPerLine = window.innerWidth > 1440 ? 4 : 3,
    } = props;

    const [state, dispatch] = useReducer(FilterReducer, {
      expand: defaultExpand ?? false,
      count: props.showCount || defaultShowCount,
    });

    //重置
    const onReset = (evt) => {
      formRef?.current?.resetFields?.();
      props?.onReset?.(evt);
    };

    const onOk = async () => {
      if (!formRef.current) return;

      if (Object.keys(formRef.current.getFieldsValue()).length === 0) {
        return;
      }
      //验证表单
      await formRef.current.validateFields();

      onSearch?.(formRef.current.getFieldsValue());
    };

    useEffect(() => {
      if (defaultExpand) {
        dispatch({
          type: "count",
          data: formItems.length,
        });
      }
    }, []);

    let buttons: Array<JSX.Element> = [
      <Button
        key={"search-btn"}
        type="primary"
        htmlType="submit"
        onClick={onOk}
        icon={<SearchIcon></SearchIcon>}
      >
        搜索
      </Button>,
      <Button onClick={onReset} key={"reset-btn"} icon={<ResetIcon></ResetIcon>}>
        重置
      </Button>,
    ];

    if (expandable) {
      buttons.push(
        <a
          key={"expandable-link"}
          style={{ fontSize: 14 }}
          onClick={() => {
            dispatch({
              type: "expand",
              data: {
                expand: !state.expand,
                count: !state.expand
                  ? props.formItems.length
                  : defaultShowCount,
              },
            });
          }}
        >
          <ExpandIcon status={state.expand}>
            {!state.expand ? <>+ 展开</> : <>- 收起</>}
          </ExpandIcon>
        </a>
      );
    }

    const _filterAction = () => {
      return <Space>{buttons}</Space>;
    };

    useImperativeHandle(ref, () => formRef.current);

    return (
      <>
        <FormComponent
          ref={formRef}
          formItems={
            props.expandable ? formItems.slice(0, state.count) : formItems
          }
          preserve={true}
          className={actionButtonsPosition ?? "inline-filter-form"}
          formType={"filter-form"}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          itemsPerLine={itemsPerLine}
          rowLayout={{ md: 12, lg: 8, xxl: 6, sm: 24 }}
          actionButtons={() =>
            !!actionButtons
              ? actionButtons(buttons, formRef.current)
              : _filterAction()
          }
          actionButtonsPosition={actionButtonsPosition}
        />
      </>
    );
  }
);

export default FilterForm;
