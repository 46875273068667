import { Col, Form, Radio, Row } from "antd";
import React from "react";

const CustomRadio = (props) => {
  const {
    options,
    columnSpan,
    showPrefix,
    radioPrefix,
    showPrefixColon,
    label = "",
    value,
    onChange,
    ...restProps
  } = props;

  if (!!options) {
    const formInstance = Form.useFormInstance();

    console.log(columnSpan,typeof columnSpan != "undefined", "props---");
    //当不需要row、col展示的时候
    if (typeof columnSpan != "undefined") {
      if (showPrefix || columnSpan === 0) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ padding: "0 4px 0 0" }}>
              {radioPrefix}
              {!!radioPrefix && showPrefixColon ? ":" : ""}
            </div>
            <Radio.Group
              {...restProps}
              options={
                Object.prototype.toString.call(options) === "[object Function]"
                  ? options(formInstance, restProps)
                  : options
              }
              value={value}
              onChange={onChange}
            ></Radio.Group>
          </div>
        );
      }

      console.log(columnSpan, "props---");

      return (
        <Radio.Group {...restProps} value={value} onChange={onChange}>
          <Row>
            {Object.prototype.toString.call(options) === "[object Function]"
              ? options(formInstance, restProps)
              : options.map((item, index) => {
                  return (
                    <Col
                      key={`column-index-${index}`}
                      xs={24}
                      sm={24 / columnSpan}
                    >
                      <Radio
                        value={item.value}
                        disabled={item.disabled || false}
                      >
                        {item.label}
                      </Radio>
                    </Col>
                  );
                })}
          </Row>
        </Radio.Group>
      );
    }
    return (
      <>
        <Radio.Group
          value={value}
          onChange={onChange}
          {...restProps}
          options={
            Object.prototype.toString.call(options) === "[object Function]"
              ? options(formInstance, restProps)
              : options
          }
        ></Radio.Group>
      </>
    );
  }
  return (
    <Radio {...restProps} value={value} onChange={onChange}>
      {label}
    </Radio>
  );
};

export default CustomRadio;
