//是否可以继续添加
export const isAddable = ({ disabled, editable, max, fieldLength }) => {
  return (
    !disabled && editable && (typeof max === "undefined" || max >= fieldLength)
  );
};

interface IRemovableProps {
  removable: boolean | ((data: any) => void) | any;
  data?: any;
  editable: boolean;
  itemIndex?: number;
  min?: number;
  fieldLength?: number;
  disabled?: boolean;
}

//是否删除
export const isRemovable = ({
  removable,
  data,
  editable,
  itemIndex,
  min,
  fieldLength,
  disabled,
  ...restProp
}: IRemovableProps) => {
  let _isRemovable = editable != false && disabled != true;

  //未设置最小行数或者当前行大于最小行数
  if (typeof min === "undefined" || fieldLength > min)
    _isRemovable = _isRemovable && true;

  if (Object.prototype.toString.call(removable) === "[object Function]") {
    _isRemovable =
      _isRemovable &&
      removable?.({
        ...restProp,
        type: "operator",
        itemIndex,
      });
  } else _isRemovable = _isRemovable && removable != false;

  return _isRemovable;
};
