import { Col, Form, Row, Space } from "antd";
import React, { useCallback } from "react";
import { IFormGroupItemProps, IFormItemProps } from "../../../../types";
import RenderFormItem from "../FormItemNormal";
import "./index.scss";
import { handleFormItemLabel } from "../../utils";

const RenderGroupItem = (props: IFormGroupItemProps) => {
  const { spaceProps = {}, children = [] } = props;
  let { hidden, label, shouldUpdate, name, ...restProps } =
    props.formItemProps || {};

  const form = Form.useFormInstance();

  if (children.length === 0) {
    return <></>;
  }

  let _hidden = false;

  if (typeof hidden === "function") {
    shouldUpdate = typeof shouldUpdate === "undefined" ? true : shouldUpdate;
  } else {
    _hidden = hidden;
  }

  const isControlHidden = () => {
    let _isHide = hidden;

    if (typeof hidden === "function") _isHide = hidden?.(form.getFieldsValue());

    return _isHide;
  };

  restProps["className"] = "form-item-group";

  const FormGroupItem = useCallback(() => {
    let _label = handleFormItemLabel(label, form);

    return (
      <Form.Item
        hidden={typeof hidden === "boolean" ? hidden : false}
        label={_label}
        noStyle={!_label}
        {...restProps}
      >
        <Space className="form-item-group-space" wrap={true} {...spaceProps}>
          {children.map((child: IFormItemProps, index: number) => {
            const { formItemProps, formControlProps, ...restProps } = child;

            return (
              <RenderFormItem
                {...restProps}
                key={`inline-form-item-${index}`}
                formItemProps={{
                  noStyle: true,
                  ...formItemProps,
                }}
                formControlProps={formControlProps}
                index={index}
              />
            );
          })}
        </Space>
      </Form.Item>
    );
  }, []);

  //通过shouldUpdate属性控制组件的属性
  if (!!shouldUpdate) {
    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={(prev, current, info) =>
          typeof shouldUpdate === "function"
            ? shouldUpdate(prev, current, info)
            : shouldUpdate
        }
      >
        {(formProps) => {
          return !isControlHidden() && <>{FormGroupItem()}</>;
        }}
      </Form.Item>
    );
  }

  return <>{FormGroupItem()}</>;
};

export default RenderGroupItem;
