import { IFormItemProps } from "../../../../types";
import { Form } from "antd";
import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import RenderFormItem from "../FormItemNormal";
import AddButton from "./addButton";
import RemoveButton from "./removeButton";
import { isAddable, isRemovable } from "./util";
import cloneDeep from "../../../util/cloneDeep";

export const TableListContent = (props) => {
  const {
    columns,
    removable,
    formListItemActionButtons,
    getDefaultValue,
    min,
    max,
    name,
    editable,
    disabled,
    ...restProps
  } = props;
  let form = Form.useFormInstance();

  return (
    <Table
      className="ant-table form-table-list"
      style={{ width: "100%", margin: "0px auto" }}
    >
      <Thead className="ant-table-thead">
        <Tr className="ant-table-cell">
          {columns
            .map((item) => ({
              label: item.formItemProps.label,
              width: item.formControlProps?.width,
            }))
            .map((item: any, index) => {
              return (
                <Th key={`th-${index}`} width={item?.width ?? "auto"}>
                  {item.label}
                </Th>
              );
            })}
          {(isRemovable({ removable, editable, disabled }) ||
            !!formListItemActionButtons) && (
            <Th className="ant-table-cell">操作</Th>
          )}
        </Tr>
      </Thead>
      <Tbody className="ant-table-tbody">
        <Form.List name={name} initialValue={[{}, {}]} {...restProps}>
          {(fields, { add, remove, move }) => {
            return (
              <>
                {fields.map(
                  ({ key, name: fieldName, ...restField }, itemIndex) => (
                    <Tr key={key}>
                      {columns.map((child: IFormItemProps, index: number) => {
                        const { label } = child.formItemProps || {};
                        let _child = cloneDeep(child, "object");

                        const _name = _child["formItemProps"]["name"] as
                          | string
                          | number;

                        _child.formItemProps = {
                          ...restField,
                          ...child.formItemProps,
                          label: "",
                          name: [fieldName, _name],
                          shouldUpdate: () => !!props?.isDisabled,
                        };

                        const isDisabled = (disabledParam, ...params) => {
                          if (typeof disabledParam === "function") {
                            return disabledParam(params);
                          }

                          return !!disabledParam;
                        };

                        //合并可控组件的属性
                        _child.formControlProps = {
                          ...(child.formControlProps || {}),
                          disabled: isDisabled(
                            child.formControlProps?.disabled,
                            child,
                            index,
                            itemIndex,
                            form.getFieldValue(name)?.[itemIndex]?.[_name],
                            form.getFieldValue(name)?.[itemIndex]
                          ),
                        };

                        return (
                          <Td
                            className="ant-table-cell"
                            key={`list-form-item-${index}`}
                          >
                            <RenderFormItem
                              {..._child}
                              formListItemPosition={{
                                rowIndex: itemIndex,
                                columnIndex: index,
                              }}
                              index={index}
                            />
                          </Td>
                        );
                      })}
                      {(isRemovable({
                        removable,
                        editable,
                        itemIndex,
                        min,
                        fieldLength: fields.length + 1,
                        disabled,
                        data: form.getFieldValue(name)?.[itemIndex],
                      }) ||
                        !!formListItemActionButtons) && (
                        <Td className="ant-table-cell operator-cell">
                          {formListItemActionButtons?.(
                            itemIndex,
                            form.getFieldValue(name)?.[itemIndex],
                            form
                          )}
                          {isRemovable({
                            removable,
                            editable,
                            itemIndex,
                            min,
                            fieldLength: fields.length + 1,
                            disabled,
                            data: form.getFieldValue(name)?.[itemIndex],
                          }) && (
                            <RemoveButton
                              key={`operator-column-${itemIndex}`}
                              removable={removable}
                              fieldName={fieldName}
                              remove={remove}
                            ></RemoveButton>
                          )}
                        </Td>
                      )}
                    </Tr>
                  )
                )}

                {isAddable({
                  disabled,
                  editable,
                  max,
                  fieldLength: fields.length + 1,
                }) && (
                  <AddButton
                    container={({ children }) => {
                      return (
                        <Tr
                          className="form-item-action-row"
                          key={`form-item-action-row`}
                        >
                          <Td
                            className="ant-table-cell"
                            colSpan={columns.length + 1}
                          >
                            <div>{children}</div>
                          </Td>
                        </Tr>
                      );
                    }}
                    add={() => add(getDefaultValue(fields.length + 1))}
                  ></AddButton>
                )}
              </>
            );
          }}
        </Form.List>
      </Tbody>
    </Table>
  );
};
