import React, { useEffect, useState } from "react";
import { Cascader, Form } from "antd";
import kindOf, { isPromiseFn, isPromiseObj } from "../../../util/kindOf";

const CustomCascader = (props) => {
  const { options, value, onChange, loadData, ...restProps } = props;
  const formInstance = Form.useFormInstance();
  let [data, setData] = useState([]);

  const _loadData = async (...args) => {
    let _opt = [];

    if (isPromiseObj(options)) {
      _opt = await options;
    } else if (isPromiseFn(options)) {
      _opt = await options(formInstance);
    } else if (kindOf(options) === "function") {
      _opt = options(formInstance);
    } else _opt = options || [];

    setData(_opt);
  };

  useEffect(() => {
    _loadData();
  }, []);

  return (
    <Cascader
      {...restProps}
      options={data}
      onChange={onChange}
      loadData={async (selectedOptions) => {
        let _res = (await loadData(selectedOptions, data)) || {};

        let _data = (data || []).map((item) => {
          if (item.key === _res.key) return _res;

          return item;
        });

        setData([..._data]);
      }}
    ></Cascader>
  );
};

export default CustomCascader;
