import React, { useMemo, useState } from "react";
import RCColorPicker, { Color, ColorBlock } from "@rc-component/color-picker";
import Trigger from "@rc-component/trigger";
import builtinPlacements from "./placement";
import "./index.scss";

const ColorPicker = (props) => {
  const { onChange, value: formValue, onChangeComplete, ...restProp } = props;
  const [value, setValue] = useState<Color | string>(formValue || "#1677ff");

  const color = useMemo(
    () => (typeof value === "string" ? value : value.toRgbString()),
    [value]
  );

  const _change = (value) => {
    onChange(value.toHexString());
    setValue(value.toHexString());
  };

  const prefixCls = "rc-color-picker";

  return (
    <Trigger
      action={["click"]}
      prefixCls={prefixCls}
      popupPlacement="bottomLeft"
      builtinPlacements={builtinPlacements}
      popup={
        <RCColorPicker
          onChange={_change}
          value={value}
          {...restProp}
        ></RCColorPicker>
      }
    >
      <ColorBlock color={color} prefixCls={prefixCls}></ColorBlock>
    </Trigger>
  );
};

export default ColorPicker;
