import React, { useState, useEffect } from "react";
import { TreeSelect, TreeSelectProps } from "antd";

const CustomTreeSelect = (props: TreeSelectProps) => {
  const { SHOW_PARENT } = TreeSelect;
  //   const [value, setValue] = useState<string | undefined>(undefined);

  let tProps: TreeSelectProps = {
    treeCheckable: false,
    showCheckedStrategy: SHOW_PARENT,
  };

  if (props.fieldNames?.label) {
    tProps.treeNodeFilterProp = props.fieldNames?.label;
  }
  
  return (
    <TreeSelect
      treeLine={{ showLeafIcon: true }}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      dropdownMatchSelectWidth={false}
      showSearch={true}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      allowClear
      treeDefaultExpandAll
      {...tProps}
      {...props}
    ></TreeSelect>
  );
};

export default CustomTreeSelect;
