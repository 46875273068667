import { Empty, Form } from "antd";
import React from "react";
import { IFormListItemProps } from "../../../../types";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./index.scss";
import { TableListContent } from "./tableListContent";
import { CardList } from "./cardListContent";
import { handleFormItemLabel } from "../../utils";

const RenderFormTableListItem = (props: IFormListItemProps) => {
  let {
    children = [],
    listType = "table",
    formListItemActionButtons,
    min,
    max,
    removable,
    addButtonText,
    ...restProps
  } = props;

  let {
    hidden = false,
    name = "tablelist",
    shouldUpdate,
    rules = [],
    label = "",
    noStyle = false,
    disabled = false,
    editable = true,
    validateTrigger = ["onChange"],
    defaultValue = {},
    ...restItemProps
  } = props.formItemProps || {};

  const form = Form.useFormInstance();

  const isControlHidden = (): boolean => {
    if (typeof hidden === "function") return hidden?.(form.getFieldsValue());

    return hidden;
  };

  if (children?.length === 0) {
    return (
      <Form.Item
        label={handleFormItemLabel(label, {})}
        className="form-item-list"
        hidden={isControlHidden()}
        {...restItemProps}
      >
        <Empty description="没有配置字段参数"></Empty>
      </Form.Item>
    );
  }
  if (typeof hidden === "function") {
    shouldUpdate = typeof shouldUpdate === "undefined" ? true : shouldUpdate;
  }

  if (children.length === 0) {
    console.warn(`${label}children参数没配置`);
  }

  const getDefaultValue = (index) => {
    if (typeof defaultValue === "function") return defaultValue(index);

    return defaultValue;
  };

  const _filterChildren = (index?: any) => {
    return children.filter((item) => {
      if (listType === "card") return true;

      const { hidden } = item.formItemProps ?? {};

      if (!hidden) return true;

      if (typeof hidden === "boolean") return !hidden;

      return !hidden?.(form.getFieldsValue(), {
        formListItemPosition: { index: index ?? 0 },
      });
    });
  };

  return (
    <>
      <Form.Item
        noStyle
        shouldUpdate={(prev, current, info) =>
          typeof shouldUpdate === "function"
            ? shouldUpdate(prev, current, info)
            : shouldUpdate
        }
        validateTrigger={validateTrigger}
      >
        {(formProps) => {
          let _columns = _filterChildren() || [];
          console.log(!isControlHidden());
          return (
            !isControlHidden() && (
              <Form.Item
                label={handleFormItemLabel(label, form)}
                className="form-item-list"
                {...restItemProps}
              >
                {listType === "card" ? (
                  <CardList
                    columns={_columns}
                    removable={removable}
                    getDefaultValue={getDefaultValue}
                    disabled={disabled}
                    editable={editable}
                    min={min}
                    max={max}
                    name={name}
                    formListItemActionButtons={formListItemActionButtons}
                    {...restItemProps}
                    {...restProps}
                  />
                ) : (
                  <TableListContent
                    columns={_columns}
                    removable={removable}
                    getDefaultValue={getDefaultValue}
                    disabled={disabled}
                    editable={editable}
                    min={min}
                    max={max}
                    name={name}
                    formListItemActionButtons={formListItemActionButtons}
                    {...restItemProps}
                    {...restProps}
                  />
                )}
                {/* </div> */}
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    </>
  );
};

export default RenderFormTableListItem;
